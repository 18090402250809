const keys = {
  PAGE_LENGTH: 15,
  ADMIN: "Admin",
  SUPER_ADMIN: "Super Admin",
  IM: "IM",
  TF: "TF",

  ACCOUNT_STUDENT: "Student",
  ACCOUNT_TEACHER: "Teacher",
  ACCOUNT_ADMIN: "Admin",
  ACCOUNT_SUPER_ADMIN: "Super Admin",
  ACCOUNT_BUSINESS_DEVELOPER: "Business Developer",
  ACCOUNT_SENIOR_ACADEMIC_COUNSELOR: "Senior Academic Counselor",
  ACCOUNT_JUNIOR_ACADEMIC_COUNSELOR: "Junior Academic Counselor",

  PRODUCTION: "PRODUCTION",
  STAGING: "STAGING",
  LOCAL: "LOCAL",
  /* subscription  type */
  BASIC_PLAN: "Basic",
  STANDARD_PLAN: "Standard",
  PREMIUM_PLAN: "Premium",
  ADD_ON_PLAN: "Add-on",

  PROFILE_COMPLETE: "Completed",
  PROFILE_INCOMPLETE: "In-Completed",
  PROFILE_ACTIVE: "Active",
  CREDIT: "Credit",
  DEBIT: "Debit",

  /* action keys */
  RE_ASSIGN: "Re-Assign",
  RE_SCHEDULE: "Re-Schedule",
  DEAD: "Dead",
  MAKES_AS_CONVERTED: "Makes As Converted",
  COMMENT: "Comment",
  ACTION_ADD: "action_add",
  ACTION_EDIT: "action_edit",
  SCHEDULE_DEMO: "Schedule Demo",

  CONTRACT_PENDING: "Pending",
  CONTRACT_EXPIRED: "Expired",
  CONTRACT_ACTIVE: "Active",
  CONTRACT_REJECTED: "Rejected",
  INTERVIEW_SCHEDULED: "Interview Scheduled",

  RESULT_PENDING: "Pending",
  RESULT_SELECTED: "Selected",
  RESULT_REJECTED: "Rejected",

  HOMEWORK_PENDING: "Pending",
  HOMEWORK_COMPLETED: "Completed",
  HOMEWORK_PARTIALLY_COMPLETED: "Partially Completed",
  HOMEWORK_NOT_COMPLETED: "Not Completed",

  PAYMENT_FAILED: "Failed",
  PAYMENT_SUCCESS: "Success",
  PAYMENT_PROCESSING: "Processing",
  PAYMENT_INITIATED: "Initiated",
  PAYMENT_UPCOMING: "Upcoming",
  PAYMENT_OVERDUE: "Overdue",

  TYPE_STUDENT_REGISTRATION: "student_registration",
  TYPE_TUTOR_REGISTRATION: "tutor_registration",
  TYPE_SUBSCRIPTION_PAYMENT: "subscription_payment",
  TYPE_EMI_PAYMENT: "emi_payment",

  MAX_FILE_SIZE: 4,
  ACCEPTED_IMAGE_FORMATE: ["image/jpeg", "image/png", "image/jpg"],

  GENDER_OPTIONS: ["Male", "Female", "Other"],
  PAYMENT_TYPE_INSTALLMENT: "Installment",

  EMI_STATUS_PARTIALLY_PAID: "Partially Paid",
  EMI_STATUS_PAID: "Paid",
  EMI_STATUS_DUE: "Due",
  EMI_STATUS_OVERDUE: "Overdue",
};

export default keys;
