<style lang="scss">
@import url('assets/css/vutify.scss');
@import url('assets/css/font.scss');
@import url('assets/css/style.scss');


</style>
<template>
  <div id="app">
      <router-view/>
    <!-- @detected-condition fires when the connectivity status of the device changes -->
    <offline @detected-condition="handleConnectivityChange">
    </offline>
    <OfflineNotification></OfflineNotification>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import offline from 'v-offline';

export default {
  name: 'App',

  components: {
    offline,
    OfflineNotification: () => import('./components/shared/OfflineNotification'),
  },

  data: () => ({
    //
  }),
  methods: {
    ...mapActions([
      'setIsOffline', // map `this.increment()` to `this.$store.dispatch('increment')`
    ]),
    handleConnectivityChange(status) {
      console.log(status);
      this.is_offline = !status
      this.setIsOffline(!status)
    }
  }
};
</script>
